import axios from "axios";
import { isNullOrEmpty } from "../helpers/data-helpers";
interface QuestionData {
    question: string;
    answerPercentages: { [key: string]: any };
}

export function surveyForm(formId) {
    const url = `/api/v1/FormData/summary?formId=${formId}`;
    let surveyForm = document.getElementById(formId) as HTMLElement;
    let surveyFormContainer = null;
    let surveyResultsContainer = document.querySelector('[data-survey-result-page]') as HTMLElement;
    if (surveyResultsContainer) {
        
        surveyFormContainer = surveyResultsContainer;
    }
    else {
        surveyFormContainer = surveyForm.parentElement.querySelector('[data-survey-form-results-container]') as HTMLElement;
    }
    
    
    surveyFormContainer.classList.add('active');
    axios.get(url)
        .then(response => {
            const data = response.data;
            displayResults(data.questionData, surveyFormContainer);
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function displayResults(questionData: QuestionData[], surveyFormContainer) {
    const resultsDiv = surveyFormContainer.querySelector('[data-survey-form-results]');
    resultsDiv.innerHTML = ''; // Clear previous results

    questionData.forEach(entry => {
        const question = entry.question;
        const percentages = entry.answerPercentages;

        // Create a paragraph for the question
        const questionElement = document.createElement('p');
        questionElement.innerHTML = question;
        questionElement.classList.add('survey-form-question');

        // Create a list to hold the percentages
        const percentageList = document.createElement('ul');
        let largestPercentage: number = 0;
        Object.entries(percentages).forEach(([answer, percentage]) => {
            if (percentage > largestPercentage) {
                largestPercentage = percentage;
            }
        });
        for (const [answer, percentage] of Object.entries(percentages)) {
            if (!isNullOrEmpty(answer) && !isNullOrEmpty(percentage)) {
                const percentageItem = document.createElement('li');
                const calculatedPercentage = (percentage * 100).toFixed(1);
                const percentageItemData = `${calculatedPercentage}%`;
                if (percentage > largestPercentage) {
                    largestPercentage = percentage;
                }
                percentageItem.innerHTML = `<div style="--survey-answer-percent:${percentageItemData};" class="survey-form-answer-list ${(percentage == largestPercentage) ? "survey-form-percent-largest": ""}"><div class="survey-form-answer">${answer}:</div> <div class="survey-form-percent"><span class="survey-form-percent-line"></span>${percentageItemData}</div></div>`; // Convert to percentage
                percentageList.appendChild(percentageItem);
            }
        }

        // Append question and percentage list to results div
        
        const resultContainer = document.createElement('div');
        resultContainer.classList.add('survey-form-result-container');
        resultContainer.appendChild(questionElement);
        resultContainer.appendChild(percentageList);
         resultsDiv.appendChild(resultContainer);

    });
    
    surveyFormContainer.classList.add('open');
    resultsDiv.classList.add('open');
    setTimeout(function () {
        resultsDiv.querySelectorAll('.survey-form-percent-line').forEach((el) => {
            el.classList.add('animate');
        });
    }, 200);
}