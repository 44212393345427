import axios from "axios";
export function createTour(tourElement, tourItem) {
    //create tour html and append it to top or bottom of the selected element
    let tourWrapper = document.createElement("div");
    tourWrapper.id = tourItem.tourBlockId;
    tourWrapper.classList.add(tourItem.verticalPlacement, tourItem.horizontalPlacement, 'tour-wrapper');


    let closeButton = document.createElement("button");
    closeButton.innerHTML = `<i class="ph-bold ph-x"></i><span class="visually-hidden">close</span>`;
    closeButton.onclick = (e) => { closeTour(tourItem.tourBlockId, tourElement) };

    let tourHtml = document.createElement("div");
    tourHtml.classList.add(tourItem.verticalPlacement, tourItem.horizontalPlacement, 'tour-element');
    tourHtml.innerHTML = `<svg class="tour-element-caret" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none"><path d="M6 0L12 8L0 8L6 0Z" fill="var(--jewel-primary)"/></svg><div class="tour-text"><span>${tourItem.tourText}</span></div>`;
    tourHtml.appendChild(closeButton);
    tourWrapper.appendChild(tourHtml);

    if (tourItem.verticalPlacement === 'bottom') {
        tourElement.appendChild(tourWrapper);
    }
    else {
        tourElement.prepend(tourWrapper);
    }
}


function closeTour(id, tourElement) {
    ///api call to close the tour id with cookie to persist (removed from list in backend)

    const url = `/api/tour/simpletour/${id}`;
    axios.post(url)
        .then(response => {
            const data = response.data;
            let closedTour = tourElement.querySelector('.tour-wrapper')
            closedTour.remove();
        })
        .catch(error => {
            console.error('Error:', error);
        });
}