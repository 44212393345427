import A11yDialog from 'a11y-dialog';

export class Modal {
    modal: any;
    modalElement: HTMLElement;

    constructor(modalElement: HTMLElement) {
        this.modal = new A11yDialog(modalElement);
        this.modalElement = modalElement;
        
        this.modal.on('show', () => {
            document.body.style.overflow = 'hidden';
        });
        this.modal.on('hide', () => {
            document.body.style.overflow = '';

        });
        if (this.modalElement.getAttribute('data-modal-default-open')) {
            this.modal.show();
        }
        const interstitialTrigger = Array.prototype.slice.call(document.querySelectorAll('[data-interstitial-trigger]')) as HTMLElement[];
        interstitialTrigger.forEach(trigger => {
            let triggerById = trigger.getAttribute('data-interstitial-trigger');
            let interstitialModal = null;
            if (triggerById != "") {
                interstitialModal = document.getElementById(`${triggerById}`)
            }
            else {
                interstitialModal = document.querySelector('[data-interstitial-modal]');
            }
            if (interstitialModal != null) {
                trigger.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (interstitialModal == modalElement) {
                        this.modal.show()
                    }

                })
            }
        });
    }
}
